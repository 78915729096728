
import { logger } from './log.js';
import * as tf from '@tensorflow/tfjs';

const annMath = {

  sub: function (a, b) { // pos, rot, scale
    const c = [];
    for (const i in a) {
      c[i] = a[i] - b[i];
    }

    return this.norm(c);
  },

  div: function (a, d) { // d is scalar
    const c = [];
    for (const i in a) {
      c[i] = a[i] / d;
    }

    return c;
  },

  add: function (a, b) {
    const c = [];
    for (const i in a) {
      c[i] = a[i] + b[i];
    }

    return this.norm(c);
  },

  mul: function (a, d) { // d is scalar
    const c = [];
    for (const i in a) {
      c[i] = a[i] * d;
    }

    return this.norm(c);
  },

  norm: function (c) {
    for (let i = 3; i < 6; i++) {
      if (c[i] > Math.PI) {
        c[i] -= Math.PI * 2;
      } else if (c[i] < -Math.PI) {
        c[i] += Math.PI * 2;
      }
    }

    return c;
  },

  normAngle: function (a) {
    if (a > Math.PI) {
      return a - Math.PI * 2;
    } else if (a < -Math.PI) {
      return a + Math.PI * 2;
    }

    return a;
  },

  eleMul: function (a, b) { // element-wise multiplication
    const c = [];
    for (const i in a) {
      c[i] = a[i] * b[i];
    }

    return c;
  }

};

const ml = {
  backend: tf.getBackend(),

  calibrate_axes: function (points) {
    console.log('backend of tensorflow:', tf.getBackend());
    console.log('number of points:', points.count);

    const ceneterPonts = {};
    for (let i = 0; i < points.count; i++) {
      if (points.array[i * 3] < 10 && points.array[i * 3] > -10 &&
                points.array[i * 3 + 1] < 10 && points.array[i * 3 + 1] > -10) { // x,y in [-10,10]
        const key = (10 + Math.round(points.array[i * 3])) * 100 + (Math.round(points.array[i * 3 + 1]) + 10);
        if (ceneterPonts[key]) {
          // save only  minimal index
          if (points.array[i * 3 + 2] < points.array[ceneterPonts[key] * 3 + 2]) {
            ceneterPonts[key] = i;
          }
        } else {
          ceneterPonts[key] = i;
        }
      }
    }

    const centerPointsIndices = [];
    for (const i in ceneterPonts) {
      centerPointsIndices.push(ceneterPonts[i]);
    }

    // console.log(center_point_indices);
    const points2d = centerPointsIndices.map(i => [points.array[i * 3], points.array[i * 3 + 1], points.array[i * 3 + 2]]);
    const pointsArray = points2d.flatMap(x => x);

    const sum = points2d.reduce(function (s, x) {
      return [s[0] + x[0],
        s[1] + x[1],
        s[2] + x[2]];
    }, [0, 0, 0]);
    const count = points2d.length;
    const mean = [sum[0] / count, sum[1] / count, sum[2] / count];

    const dataCentered = points2d.map(function (x) {
      return [
        x[0] - mean[0],
        x[1] - mean[1],
        x[2] - mean[2]
      ];
    });

    const normalV = this.train(dataCentered);

    window.editor.data.world.add_line(mean, [-normalV[0] * 10, -normalV[1] * 10, normalV[2] * 10]);
    window.editor.data.world.lidar.reset_points(pointsArray);
    /*

        var transMatrix = transpose(eulerAngleToRotationMatrix3By3({x:Math.atan2(normal_v[1], -1), y: 0, z: 0}));

        var transfromed_point_array = matmul(transMatrix, pointsArray, 3);

        data.world.lidar.reset_points(transfromed_point_array);

        //data.world.lidar.setSpecPontsColor(center_point_indices, {x:1,y:0,z:0});
        //data.world.lidar.updatePointsColor();
        */

    return centerPointsIndices;
  },

  train: function (dataCentered) { // data is ?*3 array.
    const XY = dataCentered.map(function (x) { return x.slice(0, 2); });
    const Z = dataCentered.map(function (x) { return x[2]; });

    const x = tf.tensor2d(XY);
    const para = tf.variable(tf.tensor2d([[Math.random(), Math.random()]]));

    const learningRate = 0.00001;
    const optimizer = tf.train.sgd(learningRate);
    para.print();
    for (let i = 0; i < 20; i++) {
      optimizer.minimize(function () {
        const dists = tf.matMul(para, x.transpose());
        const sqrdiff = tf.squaredDifference(dists, Z);
        const loss = tf.div(tf.sum(sqrdiff), sqrdiff.shape[0]);
        loss.print();
        return loss;
      });

      console.log(i);
      para.print();
    }

    const pv = para.dataSync();
    console.log('train result: ', pv);
    return [pv[0], pv[1], 1];
  },

  // data is N*2 matrix,
  lShapeFit: function (data) {
    // cos, sin
    // -sin, cos
    const A = tf.tensor2d(data);
    // A = tf.expandDims(A, [0]);

    let min = 0;
    let minIndex = 0;
    for (let i = 0; i <= 90; i += 1) {
      const obj = calObjective(A, i);

      if (min === 0 || min > obj) {
        minIndex = i;
        min = obj;
      }
    }

    console.log(minIndex, min);
    return min;

    // end of func

    function calObjective (A, theta) {
      const r = theta * Math.PI / 180;
      const bases = tf.tensor2d([[Math.cos(r), -Math.sin(r)],
        [Math.sin(r), Math.cos(r)]]);

      const proj = tf.matMul(A, bases); // n * 2
      const max = tf.max(proj, 0); // 1*2
      const min = tf.min(proj, 0); // 1*2
      const distToMin = tf.sum(tf.square(tf.sub(proj, min)), 0);
      const distToMax = tf.sum(tf.square(tf.sub(max, proj)), 0);

      // axis 0
      let dist0, dist1; // dist to axis 0, axis 1
      if (distToMin.gather(0).dataSync() < distToMax.gather(0).dataSync()) {
        dist0 = tf.sub(proj.gather([0], 1), min.gather(0));
      } else {
        dist0 = tf.sub(max.gather(0), proj.gather([0], 1));
      }

      if (distToMin.gather(1).dataSync() < distToMax.gather(1).dataSync()) {
        dist1 = tf.sub(proj.gather([1], 1), min.gather(1));
      } else {
        dist1 = tf.sub(max.gather(1), proj.gather([1], 1));
      }

      // concat dist0, dist1
      const minDist = tf.concat([dist0, dist1], 1).min(1);
      return minDist.sum().dataSync()[0];
    }
  },

  predictRotation: function (data) {
    const req = new Request('/api/predictRotation');
    const init = {
      method: 'POST',
      body: JSON.stringify({ points: data })
    };
    // we defined the xhr
    console.log('start predict rotatoin.', data.length, 'points');

    return fetch(req, init)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          console.log('predict rotatoin response received.');
          return response.json();
        }
      })
      .catch(reject => {
        console.log('error predicting yaw angle!');
      });
  },

  // autoadj is async
  interpolateAnnotation: async function (anns, autoAdj, onFinishOneBox) {
    let i = 0;
    while (true) {
      while (i + 1 < anns.length && !(anns[i] && !anns[i + 1])) {
        i++;
      }

      const start = i;
      i += 2;

      while (i < anns.length && !anns[i]) {
        i++;
      }

      if (i < anns.length) {
        const end = i;
        // insert (begin, end)
        let step = annMath.div(annMath.sub(anns[end], anns[start]), (end - start));

        for (let inserti = start + 1; inserti < end; inserti++) {
          let tempAnn = annMath.add(anns[inserti - 1], step);

          if (autoAdj) {
            try {
              const adjustedAnn = await autoAdj(inserti, tempAnn);

              const adjustedYaw = annMath.normAngle(adjustedAnn[5] - tempAnn[5]);

              if (Math.abs(adjustedYaw) > Math.PI / 2) {
                console.log('adjust angle by Math.PI.');
                adjustedAnn[5] = annMath.normAngle(adjustedAnn[5] + Math.PI);
              }

              if (!window.pointsGlobalConfig.enableAutoRotateXY) {
                // adjustedAnn[3] = tempAnn[3];
                // adjustedAnn[4] = tempAnn[4];
                adjustedAnn[3] = 0;
                adjustedAnn[4] = 0;
              }

              tempAnn = adjustedAnn;
            } catch (e) {
              console.log(e);
            }
            //
          }

          anns[inserti] = tempAnn;

          // adjust step since we have finished annotate one more box.
          step = annMath.div(annMath.sub(anns[end], anns[inserti]), (end - inserti));

          if (onFinishOneBox) { onFinishOneBox(inserti); }
        }
      } else {
        break;
      }
    }

    // interpolate finished

    // forward
    i = 0;
    while (i < anns.length && !anns[i]) { i++; }

    if (i < anns.length) {
      const filter = new MaFilter(anns[i]);
      i++;

      while (i < anns.length && anns[i]) {
        filter.update(anns[i]);
        i++;
      }

      while (i < anns.length && !anns[i]) {
        let tempAnn = filter.predict();

        if (autoAdj) {
          try {
            const adjustedAnn = await autoAdj(i, tempAnn);

            const adjustedYaw = annMath.normAngle(adjustedAnn[5] - tempAnn[5]);

            if (Math.abs(adjustedYaw) > Math.PI / 2) {
              console.log('adjust angle by Math.PI.');
              adjustedAnn[5] = annMath.normAngle(adjustedAnn[5] + Math.PI);
            }

            tempAnn = adjustedAnn;

            filter.update(tempAnn);
          } catch (error) {
            console.log(error);
            filter.nextStep(tempAnn);
          }
        } else {
          filter.nextStep(tempAnn);
        }

        anns[i] = tempAnn;
        // we should update
        if (onFinishOneBox) { onFinishOneBox(i); }

        i++;
      }
    }
    // now extrapolate

    // backward
    i = anns.length - 1;
    while (i >= 0 && !anns[i]) { i--; }

    if (i >= 0) {
      const filter = new MaFilter(anns[i]);
      i--;

      while (i >= 0 && anns[i]) {
        filter.update(anns[i]);
        i--;
      }

      while (i >= 0 && !anns[i]) {
        let tempAnn = filter.predict();
        if (autoAdj) {
          const adjustedAnn = await autoAdj(i, tempAnn).catch(e => {
            logger.log(e);
            return tempAnn;
          });

          const adjustedYaw = annMath.normAngle(adjustedAnn[5] - tempAnn[5]);

          if (Math.abs(adjustedYaw) > Math.PI / 2) {
            console.log('adjust angle by Math.PI.');
            adjustedAnn[5] = annMath.normAngle(adjustedAnn[5] + Math.PI);
          }

          tempAnn = adjustedAnn;

          filter.update(tempAnn);
        } else {
          filter.nextStep(tempAnn);
        }

        anns[i] = tempAnn;
        if (onFinishOneBox) { onFinishOneBox(i); }
        i--;
      }
    }

    return anns;
  }

};

// function MaFilter_tf (initX) { // moving average filter
//   this.x = tf.tensor1d(initX) // pose
//   this.step = 0

//   this.v = tf.zeros([9]) // velocity
//   this.decay = tf.tensor1d([0.7, 0.7, 0.7,
//     0.7, 0.7, 0.7,
//     0.7, 0.7, 0.7])

//   this.update = function (x) {
//     if (this.step === 0) {
//       this.v = tf.sub(x, this.x)
//     } else {
//       this.v = tf.add(tf.mul(tf.sub(x, this.x), this.decay),
//         tf.mul(this.v, tf.sub(1, this.decay)))
//     }

//     this.x = x
//     this.step++
//   }

//   this.predict = function () {
//     const pred = tf.concat([tf.add(this.x, this.v).slice(0, 6), this.x.slice(6)])
//     return pred.dataSync()
//   }

//   this.nextStep = function (x) {
//     this.x = x
//     this.step++
//   }
// }

function MaFilter (initX) { // moving average filter
  this.x = initX; // pose
  this.step = 0;

  this.v = [0, 0, 0, 0, 0, 0, 0, 0, 0]; // velocity
  this.ones = [1, 1, 1, 1, 1, 1, 1, 1, 1];
  this.decay = [0.5, 0.5, 0.5,
    0.5, 0.5, 0.5,
    0.5, 0.5, 0.5];

  this.update = function (x) {
    if (this.step === 0) {
      this.v = annMath.sub(x, this.x);
    } else {
      this.v = annMath.add(annMath.eleMul(annMath.sub(x, this.x), this.decay),
        annMath.eleMul(this.v, annMath.sub(this.ones, this.decay)));
    }

    this.x = x;
    this.step++;
  };

  this.predict = function () {
    const pred = [...annMath.add(this.x, this.v).slice(0, 6), ...this.x.slice(6)];
    return pred;
  };

  this.nextStep = function (x) {
    this.x = x;
    this.step++;
  };
}

export { ml, MaFilter };
